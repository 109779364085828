import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

import Dashboard from "./pages/Dashboard/Dashboard";
import Shipments from "./pages/Shipments/Shipments";
import Waybill from "./pages/Waybill/Waybill";

import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";

import VerifyTelephone from "./pages/Register/VerifyTelephone/VerifyTelephone";
import AddProfilePicture from "./pages/Register/AddProfilePicture/AddProfilePicture";
import VerifyEmail from "./pages/Register/VerifyEmail/VerifyEmail";
import LoginDetails from "./pages/Register/LoginDetails/LoginDetails";
import PersonalInfo from "./pages/Register/PersonalInfo/PersonalInfo";
import ContactInfo from "./pages/Register/ContactInfo/ContactInfo";
import TransportInfo from "./pages/Register/TransportInfo/TransportInfo";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/shipments" element={<Shipments />} />
        <Route path="/waybill/:id" element={<Waybill />} />

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/register" element={<PersonalInfo />} />
        <Route path="/register/contact-info" element={<ContactInfo />} />
        <Route path="/register/transport-info" element={<TransportInfo />} />
        <Route path="/register/login-details" element={<LoginDetails />} />
        <Route path="/register/verify-email" element={<VerifyEmail />} />
        <Route
          path="/register/verify-telephone"
          element={<VerifyTelephone />}
        />
        <Route path="/register/add-dp" element={<AddProfilePicture />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
