import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCountries } from "../../store/types/app.types";
import {
  updateCountries,
  updateOverview,
  updateTransportTypes,
} from "../../store/cacheReducer";

import { SelectBoxDataType } from "../../components/SelectBox/SelectBox";
import { updateRiderData } from "../../store/riderReducer";

const useData = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.rider.accessToken
  );

  // Unhandled error

  // No unhandled error
  const fetchProfile = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: `/auth/profile`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateRiderData(res.data.data));

          resolve("User profile fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching profile");
        });
    });
  }, [accessToken, dispatch]);

  const fetchCountries = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/countries",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const countries: tCountries = res.data.data;

          const SelectCountriesData: SelectBoxDataType = [];

          for (const country of countries) {
            SelectCountriesData.push({
              key: country._id,
              value: {
                img: country.FlagImgPath,
                text: `${country.Name} (${country.TelephoneCode})`,
              },
            });
          }

          dispatch(
            updateCountries({
              countries,
              countriesSelectData: SelectCountriesData,
            })
          );

          resolve("Currencies fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching Currencies");
        });
    });
  }, [accessToken, dispatch]);

  const fetchTransportTypes = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/riders/transport-types",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateTransportTypes(res.data.data));

          resolve("Transport types fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching transport types");
        });
    });
  }, [accessToken, dispatch]);

  const fetchOverview = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: `/shipments/rider-overview`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateOverview(res.data.data));

          resolve(res.data.data);
        })
        .catch((err) => {
          resolve("Error fetching overview");
        });
    });
  }, [accessToken, dispatch]);

  return {
    fetchProfile,
    fetchCountries,
    fetchTransportTypes,
    fetchOverview,
  };
};

export default useData;
