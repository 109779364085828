import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import { chevronForward } from "ionicons/icons";

import { tRootState } from "../../../store";

import useAlert from "../../../hooks/useAlert/useAlert";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import { updatePersonalInfo } from "../../../store/registrationReducer";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    firstName: CHDFirstName,
    lastName: CHDLastName,
    address: CHDAddress,
  } = useSelector((state: tRootState) => state.registration);

  const [message, set_message, clear_message] = useAlert();

  const [firstName, setFirstName] = useState(CHDFirstName);
  const [lastName, setLastName] = useState(CHDLastName);
  const [address, setAddress] = useState(CHDAddress);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!firstName || !lastName || !address)
      return set_message("warning", "Fill in all fields");

    dispatch(updatePersonalInfo({ firstName, lastName, address }));

    navigate("/register/contact-info");
  };

  useEffect(() => {
    clear_message();
  }, [firstName, lastName, address, clear_message]);

  return (
    <AuthLayout>
      <main className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Personal Info?</h3>
            <p className="auth__sub-heading">
              Kindly fill in your personal information
            </p>
          </div>
          <div className="auth__header2-right">
            <p className="auth__header2-step">Step 1/7</p>
            <div className="auth__header2-step-navigate">
              <button onClick={() => navigate("/register/contact-info")}>
                <IonIcon icon={chevronForward} />
              </button>
            </div>
          </div>
        </div>
        <form className="auth__form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>First name</label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Last name</label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <textarea
              rows={5}
              className="form-input"
              placeholder="Enter address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></textarea>
          </div>
          <button className="button mt-small">Continue</button>
          {message}
        </form>
        <p className="mt-small text-center">
          Already have an account?{" "}
          <Link to="/login" className="link">
            Login
          </Link>
        </p>
      </main>
    </AuthLayout>
  );
};

export default PersonalInfo;
