import { FormEvent, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { tRootState } from "../../../store";
import { updateRiderData } from "../../../store/riderReducer";
import { updateOverview } from "../../../store/cacheReducer";

import api_client from "../../../api/client";

import withAuth from "../../../hoc/WithAuth/withAuth";

import useAlert from "../../../hooks/useAlert/useAlert";
import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";

const AddProfilePicture = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: tRootState) => state.rider);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [picture, setPicture] = useState<File | null>(null);

  const [message, set_message] = useAlert();

  const handleFinish = (e: FormEvent<HTMLButtonElement>) => {
    if (!picture)
      return set_message("warning", "You need to upload your picture");

    if (picture.size > 2000000)
      return set_message("warning", "File size must not be greater than 2MB");

    const pictureSplit = picture.name.toLowerCase().split(".");

    if (
      !["jpg", "png", "jpeg"].includes(pictureSplit[pictureSplit.length - 1]) ||
      !picture.type.startsWith("image/")
    )
      return set_message("warning", "Image must be .png, .jpg or .jpeg");

    const submitButton = e.target as HTMLButtonElement;

    submitButton.setAttribute("disabled", "disabled");
    submitButton.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Finishing up...`;

    const form_data = new FormData();
    form_data.append("file", picture);

    let data: any;

    api_client({
      method: "PATCH",
      url: "/riders/update-profile-picture",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      data: form_data,
    })
      .then((res) => {
        data = res.data.data;

        return api_client({
          url: `/shipments/rider-overview`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      })
      .then((res) => {
        dispatch(updateOverview(res.data.data));
        dispatch(updateRiderData(data));
        navigate("/");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          set_message("warning", err.response.data.message);
        } else {
          set_message("error", err.message);
        }
      })
      .finally(() => {
        submitButton.removeAttribute("disabled");
        submitButton.innerHTML = "Finish";
      });
  };

  return (
    <AuthLayout>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length) {
            setPicture(files[0]);
          }
        }}
      />
      <div className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Add your picture</h3>
            <p className="auth__sub-heading">
              This will represent your profile photo. It will help us verify
              your account.
            </p>
          </div>
          <div className="auth__header2-right">
            <p className="auth__header2-step">Step 7/7</p>
          </div>
        </div>
        <div className="align-center mb-medium">
          {picture ? (
            <img
              src={URL.createObjectURL(picture)}
              alt=""
              className="auth__img"
            />
          ) : (
            <img
              src="https://alley-whatsapp-clone.netlify.app/assets/img/user-avatar.svg"
              alt=""
              className="auth__img"
            />
          )}
        </div>
        <p className="auth__sub-heading align-center mb-medium">
          <strong>Note:</strong> Uploaded image should not be larger than 2MB is
          size and only the following file formats are accepted: png, jpg, jpeg
        </p>
        <div className="button-group-2">
          <button
            className={picture ? "button1" : "button"}
            onClick={() => {
              if (!fileInputRef.current) return;
              fileInputRef.current.value = "";
              fileInputRef.current.click();
            }}
          >
            {picture ? "Change Image" : "Select Image"}
          </button>
          {picture ? (
            <button className="button" onClick={handleFinish}>
              Finish
            </button>
          ) : null}
        </div>
        <div className="mt-medium">{message}</div>
      </div>
    </AuthLayout>
  );
};

export default withAuth(AddProfilePicture);
