import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { tRootState } from "../../store";

import { getRegistrationStep, getStepPath } from "../../utils/rider";

const withAuth =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const currentPath = useLocation().pathname;

    const { loggedIn, accessToken, rider } = useSelector(
      (state: tRootState) => state.rider
    );

    if (!loggedIn || !accessToken || !rider) return <Navigate to="/login" />;

    const step = getRegistrationStep(rider);

    if (step) {
      const stepPath = getStepPath(step);
      if (![currentPath, currentPath + "/"].includes(stepPath))
        return <Navigate to={stepPath} />;
    } else {
      if (currentPath.startsWith("/register")) {
        return <Navigate to="/" />;
      }
    }

    return <Component {...props} />;
  };

export default withAuth;
