import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import { chevronBack, eyeOffOutline, eyeOutline } from "ionicons/icons";

import api_client from "../../../api/client";

import { tRootState } from "../../../store";
import { login } from "../../../store/riderReducer";
import { clearRegistration } from "../../../store/registrationReducer";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import useAlert from "../../../hooks/useAlert/useAlert";

const LoginDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    firstName,
    lastName,
    address,

    bvn,
    telephoneCountry,
    telephone,
    emailAddress,

    type,
    description,
    vin,
    color,
    licensePlate,
    licenseExpiryDate,
    insurancePolicyNumber,
    insuranceCompany,
  } = useSelector((state: tRootState) => state.registration);

  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, set_message, clear_message] = useAlert();

  const handleSignup = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username || !password || !confirmPassword)
      return set_message("warning", "Fill in all fields");

    if (password !== confirmPassword)
      return set_message("warning", "Passwords do not match");

    submitBtnRef.current.setAttribute("disabled", "disabled");
    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;

    api_client({
      url: "/riders/signup",
      method: "POST",
      data: {
        FirstName: firstName,
        LastName: lastName,
        Address: address,

        BVN: bvn,
        EmailAddress: emailAddress,
        TelephoneCountry: telephoneCountry?._id,
        Telephone: telephone,

        Type: type,
        Description: description,
        VIN: vin,
        Color: color,
        LicensePlate: licensePlate,
        LicenseExpiryDate: licenseExpiryDate,
        InsurancePolicyNumber: insurancePolicyNumber,
        InsuranceCompany: insuranceCompany,

        Username: username,
        Password: password,
      },
    })
      .then((res) => {
        dispatch(login(res.data.data));

        navigate("/register/verify-email");

        const interval = window.setInterval(() => {
          dispatch(clearRegistration());
          window.clearInterval(interval);
        }, 5000);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          set_message("warning", err.response.data.message);
        } else {
          set_message("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Login";
        }
      });
  };

  useEffect(() => {
    clear_message();
  }, [username, password, clear_message]);

  if (!firstName || !lastName || !address) return <Navigate to="/register" />;
  if (!bvn || !telephoneCountry || !telephone || !emailAddress)
    return <Navigate to="/register/contact-info" />;
  if (
    !type ||
    !description ||
    !vin ||
    !color ||
    !licensePlate ||
    !licenseExpiryDate ||
    !insurancePolicyNumber ||
    !insuranceCompany
  )
    return <Navigate to="/register/transport-info" />;

  return (
    <AuthLayout>
      <main className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Login details</h3>
            <p className="auth__sub-heading">
              Kindly fill in your login details
            </p>
          </div>
          <div className="auth__header2-right">
            <p className="auth__header2-step">Step 4/7</p>
            <div className="auth__header2-step-navigate">
              <button onClick={() => navigate("/register/transport-info")}>
                <IonIcon icon={chevronBack} />
              </button>
            </div>
          </div>
        </div>
        <form className="auth__form" onSubmit={handleSignup}>
          <div className="form-group">
            <label>Username</label>
            <input
              type="username"
              className="form-input"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
            <span>
              <strong>NOTE:</strong> Password is case sensitive
            </span>
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <div className="form-input-group">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter password again"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword((sp) => !sp)}
              >
                <IonIcon
                  icon={showConfirmPassword ? eyeOffOutline : eyeOutline}
                />
              </button>
            </div>
          </div>
          <button className="button mt-small" ref={submitBtnRef}>
            Continue
          </button>
          {message}
        </form>
      </main>
    </AuthLayout>
  );
};

export default LoginDetails;
