import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  closeOutline,
  documentTextOutline,
  ellipsisVertical,
  syncOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tShipment, tShipments } from "../../store/types/shipping.types";

import useAlert from "../../hooks/useAlert/useAlert";

import { SHIPMENTS_STATUS } from "../../data";

import { Entries } from "../../utils/utils.types";

// optimized solution for time between setting route and opening select rider modal
const ShipmentActions = ({
  shipment,
  setShipments,
}: {
  shipment: tShipment;
  setShipments: React.Dispatch<React.SetStateAction<tShipments>>;
}) => {
  const accessToken = useSelector(
    (state: tRootState) => state.rider.accessToken
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const [updateStatus, setUpdateStatus] = useState("");

  const [status, setStatus] = useState("");
  const [activity, setActivity] = useState("");
  const [customActivity, setCustomActivity] = useState("");
  const [reason, setReason] = useState("");
  const [deliveryCode, setDeliveryCode] = useState("");

  const updateStatusBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert();

  const updateShipmentStatus = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!status || !activity)
      return setMessage("warning", "Fill in all fields");

    if (activity === "custom" && !customActivity)
      return setMessage("warning", "Fill in all fields");

    if (status === "CANCELED" && !reason)
      return setMessage("warning", "Fill in all fields");

    if (status === "DELIVERED" && !deliveryCode)
      return setMessage("warning", "Fill in all fields");

    const btnHTML = updateStatusBtnRef.current.innerHTML;

    updateStatusBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    updateStatusBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: `/shipments/${updateStatus}`,
      method: "PATCH",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Status: status,
        Activity: activity === "custom" ? customActivity : activity,
        Reason: reason,
        DeliveryCode: deliveryCode,
      },
    })
      .then((res) => {
        setShipments((shipmts) =>
          shipmts.map((shipment) =>
            shipment._id === res.data.data._id ? res.data.data : shipment
          )
        );
        setUpdateStatus("");
        setSuccess({
          title: "Status updated!",
          message: "Shipment status has been successfully updated",
        });
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage(
            "error",
            "An error occured while trying to update status. Try again"
          );
        }
      })
      .finally(() => {
        updateStatusBtnRef.current.removeAttribute("disabled");
        updateStatusBtnRef.current.innerHTML = btnHTML;
      });
  };

  useEffect(() => {
    clearMessage();
  }, [status, activity, customActivity, clearMessage]);

  useEffect(() => {
    if (updateStatus) return;

    setStatus("");
    setActivity("");
    setCustomActivity("");
    setReason("");
    setDeliveryCode("");
  }, [updateStatus]);

  useEffect(() => {
    if (status === "DELIVERED") setActivity("Package has been delivered");
    if (status === "TRANSIT") setActivity("Package is out for delivery");
  }, [status]);

  return (
    <>
      {error ? (
        <div>
          <div className="success-modal">
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            <p className="text-center">{error}</p>
          </div>
          <div className="overlay" onClick={() => setError("")}></div>
        </div>
      ) : null}
      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p>{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}
      <div>
        <form
          className={cls("modal modal--sm", updateStatus && "modal--open")}
          onSubmit={updateShipmentStatus}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Update Status</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setUpdateStatus("")}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>
                  Status <span>*</span>
                </label>
                <select
                  className="form-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">-- Select Status --</option>
                  {Object.keys(SHIPMENTS_STATUS).map((status) => (
                    <option key={status}>{status}</option>
                  ))}
                </select>
              </div>
              {status && status !== "DELIVERED" ? (
                <div className="form-group">
                  <label>
                    Activity <span>*</span>
                  </label>
                  <select
                    className="form-select"
                    value={activity}
                    onChange={(e) => setActivity(e.target.value)}
                  >
                    <option value="">-- Select Activity --</option>
                    <option>custom</option>
                    {(
                      Object.entries(
                        (SHIPMENTS_STATUS as any)[status]
                      ) as Entries<{
                        [key: string]: string;
                      }>
                    ).map(([key, value], i) => (
                      <option key={i} value={value}>
                        {key.toLowerCase()} - {value}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {activity === "custom" ? (
                <div className="form-group">
                  <label>
                    Custom Activity <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter custom activity"
                    value={customActivity}
                    onChange={(e) => setCustomActivity(e.target.value)}
                  />
                </div>
              ) : null}
              {status === "DELIVERED" ? (
                <div className="form-group">
                  <label>
                    Delivery Code <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter delivery code"
                    value={deliveryCode}
                    onChange={(e) => setDeliveryCode(e.target.value)}
                  />
                </div>
              ) : null}
              {message}
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button
                className="btn btn--primary"
                type="submit"
                ref={updateStatusBtnRef}
              >
                Update Status
              </button>
            </div>
          </div>
        </form>
        {updateStatus ? (
          <div className="overlay" onClick={() => setUpdateStatus("")}></div>
        ) : null}
      </div>
      <div className="flex-center">
        <div className="shipments-menu">
          <div className="shipments-menu__main">
            <IonIcon icon={ellipsisVertical} />
          </div>
          <div className="shipments-menu__block">
            <div className="shipments-menu__block-main">
              <a
                href={`${process.env.PUBLIC_URL}/waybill/${shipment._id}`}
                target="_blank"
                rel="noreferrer"
              >
                <IonIcon icon={documentTextOutline} /> View / Print Waybill
              </a>
              {["TRANSIT", "PRE-TRANSIT"].includes(shipment.Status) ? (
                <div onClick={() => setUpdateStatus(shipment._id)}>
                  <IonIcon icon={syncOutline} /> Update Status
                </div>
              ) : null}
            </div>
            <div className="shipments-menu__block-top"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentActions;
