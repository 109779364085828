import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { Entries } from "../utils/utils.types";

import { tCountries, tOverview } from "./types/app.types";

import { SelectBoxDataType } from "../components/SelectBox/SelectBox";
import { tShipmentStatus, tShipmentType } from "./types/shipping.types";

type CacheType = {
  shipmentTypes: tShipmentType[];
  shipmentStatus: tShipmentStatus[];
  countries: tCountries;
  countriesSelectData: SelectBoxDataType;
  transportTypes: { _id: string; Name: string }[];
  overview: tOverview | null;
};

const CacheInitialState: CacheType = {
  countries: [],
  countriesSelectData: [],
  transportTypes: [],
  overview: null,
  shipmentTypes: ["Local", "Nationwide"],
  shipmentStatus: [
    "PRE-TRANSIT",
    "TRANSIT",
    "DELIVERED",
    "RETURNED",
    "FAILED",
    "CANCELED",
    "UNKNOWN",
  ],
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: getState<CacheType>("cache", CacheInitialState),
  reducers: {
    updateCountries(
      state,
      {
        payload,
      }: PayloadAction<{
        countries: tCountries;
        countriesSelectData: SelectBoxDataType;
      }>
    ) {
      state.countries = payload.countries;
      state.countriesSelectData = payload.countriesSelectData;

      saveState("cache", current(state));
    },
    updateTransportTypes(
      state,
      { payload }: PayloadAction<CacheType["transportTypes"]>
    ) {
      state.transportTypes = payload;

      saveState("cache", current(state));
    },
    updateOverview(state, { payload }: PayloadAction<tOverview>) {
      state.overview = payload;

      saveState("cache", current(state));
    },
    clearcache(state) {
      for (const [k, v] of Object.entries(
        CacheInitialState
      ) as Entries<CacheType>) {
        state[k] = v as any; // Hmm
      }

      saveState("cache", current(state));
    },
  },
});

export const {
  clearcache,
  updateCountries,
  updateTransportTypes,
  updateOverview,
} = cacheSlice.actions;

export default cacheSlice.reducer;
