import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import api_client from "../../api/client";

import { tRootState } from "../../store";

const Logout = () => {
  const accessToken = useSelector(
    (state: tRootState) => state.rider.accessToken
  );

  useEffect(() => {
    if (!accessToken) return;

    localStorage.removeItem("appState");

    api_client({
      method: "DELETE",
      url: "/auth/logout",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .catch((err) => {
        // do nothing
      })
      .finally(() => {
        window.location.assign("/login");
      });
  }, [accessToken]);

  if (!accessToken) return <Navigate to="/login" />;

  return (
    <div className="logout-loader">
      <span className="fas fa-spinner fa-spin"></span> Logging Out...
    </div>
  );
};

export default Logout;
