import { FormEvent, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import {
  chevronBack,
  chevronDownOutline,
  chevronForward,
} from "ionicons/icons";

import { tRootState } from "../../../store";
import { tCountry } from "../../../store/types/app.types";
import { updateContactInfo } from "../../../store/registrationReducer";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import useData from "../../../hooks/useData/useData";
import useAlert from "../../../hooks/useAlert/useAlert";

import useSelectBox from "../../../hooks/useSelectBox/useSelectBox";
import { isNumber } from "../../../utils/func";

const ContactInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    firstName,
    lastName,
    address,
    bvn: CHDBvn,
    telephoneCountry: CHDTelephoneCountry,
    telephone: CHDTelephone,
    emailAddress: CHDEmailAddress,
  } = useSelector((state: tRootState) => state.registration);
  const { countries, countriesSelectData } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchCountries } = useData();

  const [bvn, setBvn] = useState<number | "">(CHDBvn);

  const [countrySelectBox, selectedCountryId, openSelectBox] = useSelectBox(
    "Select Country",
    countriesSelectData
  );
  const [telephoneCountry, setTelephoneCountry] = useState<tCountry | null>(
    CHDTelephoneCountry
  );
  const [telephone, setTelephone] = useState<number | "">(CHDTelephone);

  const [emailAddress, setEmailAddress] = useState(CHDEmailAddress);

  const [message, set_message, clear_message] = useAlert();

  const handleSignup = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!bvn || !telephoneCountry || !telephone || !emailAddress)
      return set_message("warning", "Fill in all fields");

    if (bvn.toString().length !== 11)
      return set_message("warning", "BVN should be 11 digits");

    dispatch(
      updateContactInfo({ bvn, telephoneCountry, telephone, emailAddress })
    );

    navigate("/register/transport-info");
  };

  useEffect(() => {
    if (!selectedCountryId) return;

    setTelephoneCountry(countries.find((ct) => ct._id === selectedCountryId)!);
  }, [selectedCountryId, countries]);

  useEffect(() => {
    clear_message();
  }, [bvn, telephoneCountry, telephone, emailAddress, clear_message]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  if (!firstName || !lastName || !address) return <Navigate to="/register" />;

  return (
    <AuthLayout>
      {countrySelectBox}
      <main className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Contact Info</h3>
            <p className="auth__sub-heading">
              Kindly fill in your contact information
            </p>
          </div>
          <div className="auth__header2-right">
            <p className="auth__header2-step">Step 2/7</p>
            <div className="auth__header2-step-navigate">
              <button onClick={() => navigate("/register")}>
                <IonIcon icon={chevronBack} />
              </button>
              <button onClick={() => navigate("/register/transport-info")}>
                <IonIcon icon={chevronForward} />
              </button>
            </div>
          </div>
        </div>
        <form className="auth__form" onSubmit={handleSignup}>
          <div className="form-group">
            <label>BVN</label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter bvn"
              value={bvn}
              maxLength={11}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setBvn(+e.target.value)
                    : null
                  : setBvn("")
              }
            />
          </div>
          <div className="form-group">
            <label className="form-label"> Phone Number </label>
            <div className="form-input-group form-input-group--1">
              <button onClick={openSelectBox} type="button">
                {telephoneCountry ? (
                  <p>{telephoneCountry.TelephoneCode}</p>
                ) : (
                  "Select country"
                )}
                <IonIcon icon={chevronDownOutline} />
              </button>
              <input
                type="tel"
                placeholder="Your phone number"
                value={telephone}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setTelephone(+e.target.value)
                      : null
                    : setTelephone("")
                }
                maxLength={10}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-input"
              placeholder="Enter email address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          <button className="button mt-small">Continue</button>
          {message}
        </form>
      </main>
    </AuthLayout>
  );
};

export default ContactInfo;
