import { tRider } from "../store/types/app.types";

type StepType = "verify-email" | "verify-telephone" | "add-dp";

export const getRegistrationStep = (
  riderDetails: tRider | null
): StepType | null => {
  if (!riderDetails) return null;
  if (riderDetails.AccountCreationStatus === 2) return null;

  if (!riderDetails.EmailAddReference?.Verified) {
    return "verify-email";
  } else if (
    !riderDetails.TelephoneAddReference?.Verified &&
    !riderDetails.SkipTelephoneVerification
  ) {
    return "verify-telephone";
  } else if (!riderDetails.ProfilePicturePath) {
    return "add-dp";
  }

  return null;
};

export const getStepPath = (step: StepType): string => {
  if (step === "verify-email") return "/register/verify-email";
  if (step === "verify-telephone") return "/register/verify-telephone";
  if (step === "add-dp") return "/register/add-dp";

  return "/";
};
