import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import api_client from "../../../api/client";

import { tRootState } from "../../../store";
import { tRider } from "../../../store/types/app.types";
import { updateRiderData } from "../../../store/riderReducer";

import useAlert from "../../../hooks/useAlert/useAlert";

import withAuth from "../../../hoc/WithAuth/withAuth";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";

import { assertNotNull } from "../../../utils/func";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessToken, rider } = useSelector(
    (state: tRootState) => state.rider
  );

  assertNotNull<tRider>(rider);

  const [blocked, setBlocked] = useState<number>(0);
  const [blockedInterval, setBlockedInterval] = useState<number | null>(null);
  const [resendBlocked, setResendBlocked] = useState<number>(0);
  const [resendBlockedInterval, setResendBlockedInterval] = useState<
    number | null
  >(null);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [code, setCode] = useState<string>("");

  const [message, set_message, clear_message] = useAlert();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!code) return set_message("warning", "Verification code is required");

    const triggerBtn = submitBtnRef.current;

    triggerBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    triggerBtn.setAttribute("disabled", "disabled");

    api_client({
      method: "PATCH",
      url: "/riders/signup/verify-email",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ Code: code }),
    })
      .then((res) => {
        dispatch(updateRiderData(res.data.data as tRider));
        navigate("/register/verify-telephone");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          set_message("warning", err.response.data.message);
          if (err.response.data.data) {
            dispatch(updateRiderData(err.response.data.data as tRider));
          }
        } else {
          set_message("error", err.message);
        }
      })
      .finally(() => {
        triggerBtn.removeAttribute("disabled");
        triggerBtn.innerHTML = "Next";
      });
  };

  const resendVerificationCode = (
    e: React.MouseEvent<HTMLSpanElement>
  ): void => {
    const target = e.target as HTMLSpanElement;

    target.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Resending code`;

    api_client({
      method: "GET",
      url: "/riders/signup/resend-email-code",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        set_message("success", "Code resent successfully");
        dispatch(updateRiderData(res.data.data as tRider));
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          set_message("warning", err.response.data.message);
        } else {
          set_message("error", err.message);
        }
      })
      .finally(() => {
        target.innerHTML = "Resend";
      });
  };

  useEffect(() => {
    clear_message();
  }, [code, clear_message]);

  useEffect(() => {
    if (blocked <= 0 && typeof blockedInterval === "number") {
      setBlocked(0);
      window.clearInterval(blockedInterval);
      return setBlockedInterval(null);
    }

    if (blocked > 0 && blockedInterval === null) {
      setBlockedInterval(
        window.setInterval(() => {
          setBlocked((b) => b - 1000);
        }, 1000)
      );
    }
  }, [blocked, blockedInterval]);

  useEffect(() => {
    if (resendBlocked <= 0 && typeof resendBlockedInterval === "number") {
      setResendBlocked(0);
      window.clearInterval(resendBlockedInterval);
      return setResendBlockedInterval(null);
    }

    if (resendBlocked > 0 && resendBlockedInterval === null) {
      setResendBlockedInterval(
        window.setInterval(() => {
          setResendBlocked((b) => b - 1000);
        }, 1000)
      );
    }
  }, [resendBlocked, resendBlockedInterval]);

  useEffect(() => {
    if (rider.EmailAddReference?.VerificationBlockedUntil) {
      const blockedUntil = new Date(
        rider.EmailAddReference.VerificationBlockedUntil
      ).getTime();

      const currentDate = new Date().getTime();

      if (currentDate < blockedUntil) {
        setBlocked(blockedUntil - currentDate);
      }
    }

    if (rider.EmailAddReference?.ResendCodeBlockedUntil) {
      const resendBlockedUntil = new Date(
        rider.EmailAddReference.ResendCodeBlockedUntil
      ).getTime();

      const currentDate = new Date().getTime();

      if (currentDate < resendBlockedUntil) {
        setResendBlocked(resendBlockedUntil - currentDate);
      }
    }
  }, [
    rider.EmailAddReference?.ResendCodeBlockedUntil,
    rider.EmailAddReference?.VerificationBlockedUntil,
    setBlocked,
    setResendBlocked,
  ]);

  let blockedDate: null | string = null;
  let resendBlockedDate: null | string = null;

  if (blocked) {
    const date = new Date(blocked);
    blockedDate = `${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")}`;
  }

  if (resendBlocked) {
    const date = new Date(resendBlocked);
    resendBlockedDate = `${String(date.getMinutes()).padStart(2, "0")}:${String(
      date.getSeconds()
    ).padStart(2, "0")}`;
  }

  return (
    <AuthLayout>
      <main className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Verify Email</h3>
            <p className="auth__sub-heading">
              Kindly enter the 6-digit code sent to {rider.EmailAddress}
            </p>
          </div>
          <div className="auth__header2-right">
            <p className="auth__header2-step">Step 5/7</p>
          </div>
        </div>
        <form className="auth__form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label"> Kindly enter 6-digit code </label>
            <input
              type="text"
              className="form-input"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          {!blocked ? (
            <p className="auth__footer mt-small">
              Don't receive code?{" "}
              {resendBlocked ? (
                <>
                  <span className="auth__link">Resend</span> in{" "}
                  <span className="auth__link">{resendBlockedDate}</span>
                </>
              ) : (
                <span className="auth__link" onClick={resendVerificationCode}>
                  Resend
                </span>
              )}
            </p>
          ) : null}
          <button
            className="button"
            disabled={!code || Boolean(blocked)}
            ref={submitBtnRef}
          >
            {blocked ? <>Blocked until {blockedDate}</> : "Next"}
          </button>
          {message}
        </form>
      </main>
    </AuthLayout>
  );
};

export default withAuth(VerifyEmail);
