import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  searchOutline,
  notificationsOutline,
  chevronDownOutline,
  volumeMuteOutline,
  personOutline,
  logOutOutline,
  menuOutline,
  closeOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";

import { assertNotNull } from "../../utils/func";

import dmxLogo from "../../assets/img/dmx-logo.png";
import { useState } from "react";

const Header = () => {
  const rider = useSelector((state: tRootState) => state.rider.rider);

  assertNotNull(rider);

  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <header className={cls("header", navbarOpen && "navbar--open")}>
      <div className="header__container container">
        {/* <div className="header__brand">
          <span>D</span>MX Logistics
        </div> */}
        <div className="header__brand">
          <div
            className="navbar__btn"
            onClick={() => setNavbarOpen((op) => !op)}
          >
            <IonIcon icon={menuOutline} />
            <IonIcon icon={closeOutline} />
          </div>
          <img src={dmxLogo} alt="" className="header__logo" />
        </div>
        <ul className="navbar">
          <li className="navbar__item">
            <NavLink
              to="/"
              className={({ isActive }) =>
                cls("navbar__link", isActive && "navbar__link--active")
              }
            >
              Dashboard
            </NavLink>
          </li>
          <li className="navbar__item">
            <NavLink
              to="/shipments"
              className={({ isActive }) =>
                cls("navbar__link", isActive && "navbar__link--active")
              }
            >
              Shipments
            </NavLink>
          </li>
          {/* <li className="navbar__item">
            <NavLink
              to="/wallet"
              className={({ isActive }) =>
                cls("navbar__link", isActive && "navbar__link--active")
              }
            >
              Wallet
            </NavLink>
          </li> */}
        </ul>
        <div className="search-form">
          <IonIcon icon={searchOutline} />
          <input type="text" placeholder="Search anything" />
          <button>Find</button>
        </div>
        <div className="header__right">
          <span className="header__action">
            <IonIcon icon={volumeMuteOutline} />
          </span>
          <span className="header__action">
            <IonIcon icon={notificationsOutline} />
          </span>
          <div className="header__user">
            <div className="header__user-main">
              <img
                src={rider.ProfilePicturePath}
                alt=""
                className="header__user-img"
              />
              <IonIcon icon={chevronDownOutline} />
            </div>
            <div className="header__user-block">
              <div className="header__user-block-top"></div>
              <div className="header__user-block-main">
                <p className="header__user-email">{rider.EmailAddress}</p>
                <div className="header__user-user">
                  <img
                    src={rider.ProfilePicturePath}
                    alt=""
                    className="header__user-img"
                  />
                  <p className="header__user-name">
                    Hi, {rider.FirstName} {rider.LastName}
                  </p>
                </div>
                <ul className="header__user-menu">
                  <li className="header__user-menu-item">
                    <Link to="/profile" className="header__user-menu-link">
                      <IonIcon icon={personOutline} />
                      Profile
                    </Link>
                  </li>
                  <li className="header__user-menu-item">
                    <Link to="/logout" className="header__user-menu-link">
                      <IonIcon icon={logOutOutline} />
                      Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
